import React from "react";
import styles from './SuccessComponent.module.scss';
import Button from "../../Button/Button";
import { useLocation, useNavigate } from "react-router-dom";
import { popMessage } from "../../message/message";

const PaymentSuccessful = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const withCheckout = location.search.includes("with=checkout")
    const isRegisterPage = location.pathname.includes("register")

    const handleLaunchSuite = () => {
        localStorage.setItem("loggedIn", "true")
        if (isRegisterPage && withCheckout) {
            popMessage.successMessage("Thanks for registering")
        }
        navigate("/")
    }
    return <div className={styles.successfulComponentWrapper}>
        <div className={styles.image}>
            <img src="/images/rocket.svg" alt="" />
        </div>
        <div className={styles.main}>
            <h1 >Payment Successful!</h1>
            <p style={{
                maxWidth: "60ch"
            }}>
                Wohoo! You’ve successfully paid for the <strong>Hi-IQ</strong> plan. Hit <strong>‘Launch Suite’ </strong>to dive into the new form of news.
            </p>
            <Button style={{ width: 128, height: 38, marginInline: 'auto' }} text="Launch Suite" type="primary" weight="semibold" padding="8.5px 18px" radius="md" onClick={handleLaunchSuite} />
        </div>
    </div>;
};

export default PaymentSuccessful;
