import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import { popMessage } from "src/development/Component/message/message";

// Utility function to handle errors
export const handleError = (err: any, defaultMessage: string) => {
  switch (err.code) {
    case "LimitExceededException":
      return "Too many attempts. Please wait a few minutes before requesting another code.";
    case "UserNotFoundException":
      return "We couldn't find an account with this email address.";
    case "InvalidParameterException":
      return "Please ensure you've entered a valid email address.";
    case "UserNotConfirmedException":
      return "Your account is not verified. Please check your email for verification instructions.";
    case "NetworkError":
      return "Network error. Please check your internet connection and try again.";
    case "ThrottlingException":
      return "You've requested too many codes. Please wait a while before trying again.";
    case "InvalidEmailRoleAccessPolicyException":
      return "We're currently unable to send emails. Please try again later or contact support.";
    case "CodeMismatchException":
      return "The code you entered is incorrect. Please try again.";
    case "ExpiredCodeException":
      return "This verification code has expired. Please request a new code.";
    case "InvalidPasswordException":
      return "There was an issue with the temporary password. Please try requesting a new code.";
    default:
      return err.message || defaultMessage;
  }
};

export const usePasswordResetVerification = (
  setStep: (step: number) => void
) => {
  const [otp, setOtp] = useState<string>("");
  const [email, setEmail] = useState<string | null>(null);
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const location = useLocation();
  const navigate = useNavigate();

  // Extract email from search params
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const emailFromParams = searchParams.get("email");

    if (emailFromParams) {
      setEmail(emailFromParams);
    } else {
      navigate("/login"); // Redirect if email is not found
    }
  }, [location.search, navigate]);

  useEffect(() => {
    if (email?.trim()) {
      Auth.forgotPassword(email.trim());
    }
  }, [email]);

  const handleResendCode = async () => {
    if (!email?.trim()) {
      popMessage.errorMessage("Email not found. Please go back and try again.");
      return;
    }
    setError("");
    try {
      await Auth.forgotPassword(email.trim());
      popMessage.successMessage(
        "A new verification code has been sent to your email."
      );

      // Optional: Reset OTP input if you have it in your form
      setOtp("");
    } catch (err: any) {
      console.error("Error resending code:", err);
      const errorMessage = handleError(
        err,
        "Failed to send verification code. Please try again later."
      );
      popMessage.errorMessage(errorMessage);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    // Input validation
    if (!email?.trim()) {
      setError("Email is required");
      return;
    }

    if (!otp?.trim()) {
      setError("Please enter the verification code");
      return;
    }

    if (!/^\d{6}$/.test(otp)) {
      setError("Code must be exactly 6 digits");
      return;
    }

    setLoading(true);
    try {
      await Auth.forgotPasswordSubmit(email.trim(), otp, "DefaultPassword123#");
      await Auth.signIn(email.trim(), "DefaultPassword123#");
      localStorage.setItem("passwordResetInitiated", "true");
      setStep(2); // Proceed to the next step
    } catch (err: any) {
      console.error("Password reset verification failed:", err);
      const errorMessage = handleError(
        err,
        "Unable to verify code. Please try again or request a new code."
      );
      setError(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  return {
    otp,
    setOtp,
    error,
    setError,
    loading,
    handleSubmit,
    handleResendCode,
    email,
  };
};
