import React from "react";
import { Modal } from "antd";
//@ts-ignore
import terms from "src/style/Terms&Conditions.pdf";

const TermsDialog = (props: any) => {

  return (
    <Modal className="terms-iframe-div" open={props.openTerms} onCancel={() => props.setOpenTerms(false)} footer={null} closable={false} style={{ height: 500 }}>
      <iframe title="terms" src={terms} className="terms-iframe" />
    </Modal>
  );
};

export default TermsDialog;