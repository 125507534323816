import { z } from "zod";

export const registerSteps = ["Register", "Verify Email", "Launch Suite"];

export const registerWithCheckoutSteps = [
  "Register",
  "Verify Email",
  "Checkout",
  "Launch Suite",
];

// register form schemas

export const PASSWORD_REGEX =
  /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>]).{8,20}$/;
export const EMAIL_REGEX =
  /^(?![_.])[A-Za-z0-9._%+-]+(?<=\S)@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
export const registerFormSchema = z.object({
  email: z
    .string({ required_error: "", invalid_type_error: "" })
    .refine((val) => val === "" || EMAIL_REGEX.test(val), {
      message: "Please enter a valid email address",
    }),
  password: z
    .string()
    .regex(PASSWORD_REGEX)
    .min(6, "Password must be at least 6 characters long") // Password validation
    .nonempty("Password is required"),
  tc: z.boolean(),
  emailUpdates: z.boolean().optional().default(false),
  firstName: z.string().min(1, "First name is required"),
  lastName: z.string().min(1, "Last name is required"),
});

export const registerEmailVerificationFormSchema = z.object({
  otp: z.string().min(6).max(6).nonempty("Otp is required"),
});
