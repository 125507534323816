import React, { ChangeEvent, ClipboardEvent, Dispatch, KeyboardEvent, SetStateAction, useEffect, useRef, useState } from "react";

import './OtpInput.scss';


type Props = {
    length?: number;
    onComplete: (otp: string) => void;
    size: string;
    error?: boolean;
    gap?: string;
    changeHandler?: (otp: string) => void;
    setError?: Dispatch<SetStateAction<boolean>> | undefined
};

const OtpInput = ({ length = 6, onComplete, size, error = false, gap = "16px", changeHandler, setError }: Props) => {
    const [otp, setOtp] = useState(new Array(length).fill(''));
    const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
    const [focusedIndex, setFocusedIndex] = useState<number | null>(null); // Track focused input

    useEffect(() => {
        if (error) {
            const emptyOtp = new Array(length).fill('');
            setOtp(emptyOtp);
            setFocusedIndex(0);
            inputRefs.current[0]?.focus();
            // if(setError)setError(false);
        }
    }, [error])

    const handleChange = (e: ChangeEvent<HTMLInputElement>, index: number) => {

        const value = e.target.value;

        // Only allow a single digit (0-9) or empty input
        if (!/^[0-9]$/.test(value) && value !== '') return;

        const newOtp = [...otp];
        newOtp[index] = value;
        setOtp(newOtp);

        // Automatically focus the next input if the current one is filled
        if (value && index < length - 1) {
            inputRefs.current[index + 1]?.focus();
        }

        const currentOtp = newOtp?.filter((digit) => digit !== "")?.join("");
        if (changeHandler) changeHandler(currentOtp)

        // Trigger onComplete callback if OTP is filled
        if (newOtp.every((digit) => digit !== '')) {
            onComplete(newOtp.join(''));
        }
    };

    const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>, index: number) => {
        // Move focus on backspace
        if (e.key === 'Backspace' && index > 0 && otp[index] === '') {
            inputRefs.current[index - 1]?.focus();
        }
    };

    const handlePaste = (e: ClipboardEvent<HTMLInputElement>) => {
        // Get the pasted text
        const pastedText = e.clipboardData.getData('Text');

        // Ensure the pasted text contains only digits and slice it to fit the OTP length
        const sanitizedText = pastedText.replace(/\D/g, '').slice(0, length);

        // Update the OTP state with the sanitized pasted text
        const newOtp = [...otp];
        for (let i = 0; i < sanitizedText.length; i++) {
            newOtp[i] = sanitizedText[i];
        }
        setOtp(newOtp);

        // Focus the last input that is either empty or filled after the paste operation
        let focusSet = false;
        for (let i = 0; i < length; i++) {
            if (!newOtp[i] && !focusSet) {
                inputRefs.current[i]?.focus();
                focusSet = true;
            }
        }

        // If all inputs are filled, focus on the last one
        if (!focusSet && length > 0) {
            inputRefs.current[length - 1]?.focus();
        }

        const currentOtp = newOtp?.filter((digit) => digit !== "")?.join("");
        if (changeHandler) changeHandler(currentOtp)

        // Trigger onComplete callback if OTP is filled
        if (newOtp.every((digit) => digit !== '')) {
            onComplete(newOtp.join(''));
        }

        // Prevent the default paste behavior
        e.preventDefault();
    };

    // Handle focus event
    const handleFocus = (index: number) => {
        // If the previous input is empty, prevent focus
        setFocusedIndex(index); // Set the focused index to highlight the border
    };

    // Handle blur event
    const handleBlur = () => {
        setFocusedIndex(null); // Reset focus when the input loses focus
    };

    // useEffect(() => {
    //     setFocusedIndex(0);\

    // },[])

    return (
        <div style={{ display: 'flex', gap }} className={`otp-input-container ${error ? "error" : ""}`}>
            {otp.map((digit, index) => (
                <input
                    key={index}
                    type="text"
                    maxLength={1}
                    value={digit}
                    onChange={(e) => handleChange(e, index)}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                    ref={(el) => (inputRefs.current[index] = el)}
                    onPaste={handlePaste}
                    onFocus={() => handleFocus(index)} // Set focus index on focus
                    onBlur={handleBlur} // Reset focus index on blur
                    className={`${focusedIndex === index ? "focused" : ""}`}
                    style={{
                        width: size,
                        height: size,
                        textAlign: 'center',
                        fontSize: '20px',
                        outline: 'none', // Remove default focus outline
                    }}
                    autoFocus={index === 0}
                />
            ))}
        </div>
    );
};

export default OtpInput;
