import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import moment from "moment";
import axios from "axios";
import { Auth } from "aws-amplify";

import { loginSchema, TLoginSchema } from "src/schema/auth/auth.schema";
import { axiosInstance, updateAxiosInstance } from "src/store/utility";
import { updateLoading } from "src/store/Loading";
import { useStepper } from "src/context/StepperProvider";
import { getUrlParam } from "src/utils/utilFunction";
import { useLayoutContext } from "src/context/LayoutProvider";
import { popMessage } from "src/development/Component/message/message";
import { EMAIL_REGEX } from "src/constants/auth";

const stepperSteps = {
  withCheckout: ["Sign In", "Checkout", "Launch Suite"],
  withoutCheckout: ["Verify Email", "Sign In", "Launch Suite"],
};

const updateUrlParams = (params: Record<string, string>) => {
  const searchParams = new URLSearchParams(window.location.search);
  Object.entries(params).forEach(([key, value]) => {
    searchParams.set(key, value);
  });
  return searchParams.toString();
};

export const useLogin = () => {
  const [error, setError] = useState<string>("");
  const withCheckout = getUrlParam("with") === "checkout";

  const form = useForm<Partial<TLoginSchema>>({
    resolver: zodResolver(loginSchema.partial()),
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { setSteps } = useStepper();
  const { setActiveDrawer } = useLayoutContext();

  // Watch for changes in the email field
  const email = form.watch("email");
  const currentStep = getUrlParam("step") || "1";
  const internalStep = parseInt(getUrlParam("internalStep") || "1");

  const setInternalStep = (step: number) => {
    const searchParams = updateUrlParams({ internalStep: step.toString() });
    navigate(`${location.pathname}?${searchParams}`);
  };

  // Initialize steps for the stepper
  useEffect(() => {
    setSteps(
      withCheckout ? stepperSteps.withCheckout : stepperSteps.withoutCheckout
    );
  }, [setSteps]);

  // Clear the error message when the user starts editing the email again
  useEffect(() => {
    if (error) {
      setError("");
    }
  }, [email]);

  const paramEmail = new URLSearchParams(location.search).get("email");
  useEffect(() => {
    const decodedEmail = decodeURIComponent(paramEmail || "");
    if (paramEmail && EMAIL_REGEX.test(decodedEmail)) {
      form.setValue("email", decodedEmail);
    }
  }, [paramEmail]);

  // Handles email verification step
  const handleEmailSubmit = async (payload: Partial<TLoginSchema>) => {
    try {
      setError(""); // Clear any existing error
      const { status } = await axiosInstance.post(
        "/auth/check-email-verification",
        { email: payload.email }
      );

      if (status === 200) {
        if (withCheckout) {
          const searchParams = updateUrlParams({
            internalStep: "4",
            email: encodeURIComponent(payload?.email || ""),
          });
          navigate(`${location.pathname}?${searchParams}`);
        } else {
          const searchParams = updateUrlParams({
            step: "2",
            email: encodeURIComponent(payload?.email || ""),
          });
          navigate(`${location.pathname}?${searchParams}`);
        }
      }
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        const { status } = error.response;
        if (status === 404) {
          setError(
            "We don't recognize this email address. Try another or Sign Up to register."
          );
        } else if (status === 403) {
          const searchParams = updateUrlParams({
            internalStep: "2",
            email: encodeURIComponent(payload?.email || ""),
          });
          navigate(`${location.pathname}?${searchParams}`);
        } else {
          setError("An unexpected error occurred");
        }
      } else {
        console.error("An unexpected error occurred:", error);
        setError("An unexpected error occurred");
      }
    }
  };

  // Handles user login
  const handleLogin = async (payload: Partial<TLoginSchema>) => {
    const { data, success } = loginSchema.safeParse(payload);
    if (!success) return popMessage.errorMessage("Invalid Credentials");

    try {
      const user = await Auth.signIn(data.email, data.password);
      const authToken = user.signInUserSession.accessToken.jwtToken;

      updateAxiosInstance(authToken);
      localStorage.setItem(
        "tokenExpirationDate",
        moment().add(1, "days").toISOString()
      );

      setActiveDrawer("");
      dispatch(updateLoading({ key: "dashboardloading", value: true }) as any);
      if (withCheckout) {
        const searchParams = updateUrlParams({ step: "3" });
        navigate(`${location.pathname}?${searchParams}`);
      } else {
        localStorage.setItem("loggedIn", "true");
        navigate("/");
        popMessage.successMessage("Welcome back");
      }
    } catch (err: any) {
      if (err.code === "NotAuthorizedException") {
        form.setError("password", {
          message: "Incorrect password. Please try again.",
        });
      } else {
        form.setError("password", {
          message: "Something went wrong. Please try again.",
        });
      }
    } finally {
      dispatch(updateLoading({ key: "dashboardloading", value: false }) as any);
    }
  };

  return {
    form,
    error,
    internalStep,
    setInternalStep,
    currentStep,
    handleEmailSubmit,
    handleLogin,
  };
};
