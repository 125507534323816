import { Switch } from "antd";
import React from "react";
import './Toggle.scss';

interface ToggleProps {
    height?: string;
    width?: string;
    checked: boolean;
    onToggle: (value: boolean) => void
}

const Toggle = ({
    height = "18px",
    width = "18px",
    checked,
    onToggle
}: ToggleProps) => {
    return <div className="toggle-wrapper">
        <Switch
            style={{
                width: width,
                height: height
            }}
            checked={checked}
            onChange={onToggle}
        />
    </div>;
};

export default Toggle;
