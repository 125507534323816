export const defaultFilter = {
  keyword: "",
  boolean: "AND",
};

export const firstDefaultFilter = {
  keyword: "",
  boolean: "ONLY",
};

export const tierPrivilege: Record<string, number> = {
  low: 1,
  mid: 3,
  high: 10,
};

export const hideFilterOptionsComponents = [
  "tradingview",
  "investing-economic-calender",
  "telegram",
  "cmegroup-app",
  "marketoverview",
  "forexcrossrates",
  "stockscreener",
  "fmoccut75bps",
  "fmoccut50bps",
  "fmoccut25bps",
  "fmocunchanged",
  "fmochike25bps",
  "cryptocomparecryptonews",
  "cryptocompareadvancedchart",
  "cryptocompareprices",
  "economiccalendarwidget",
  "forexheatmap",
  "marketdatawidget",
  "stockheatmap",
  "tickertaperegular",
  "news",
  "singleticker",
  "yeartodatestockheatmap",
  "tickertapecompact",
  "watchlist",
  "financialjuiceeconomiccalendar",
  "financialjuiceheadlines",
  "financialjuicetickstrikesquawk",
  "myfxbookcalendar",
];
export const showShareOptionsComponents = [
  "fmoccut75bps",
  "fmoccut50bps",
  "fmoccut25bps",
  "fmocunchanged",
  "fmochike25bps",
];

export const filterOptions = [
  {
    label: "AND",
    value: "AND",
  },
  {
    label: "OR",
    value: "OR",
  },
  {
    label: "NOT",
    value: "NOT",
  },
];
export const firstFilterOptions = [
  {
    label: "ONLY",
    value: "ONLY",
  },
  {
    label: "NOT",
    value: "NOT",
  },
];
