import React from "react";
import { Modal } from "antd";
//@ts-ignore
import dmca from "src/style/DMCA_policy.pdf";

const DMCADialog = (props: any) => {
  return (
    <Modal className="terms-iframe-div" open={props.openDMCAPolicy} onCancel={() => props.setOpenDMCAPolicy(false)} footer={null} closable={false}>
      <iframe title="privacy" src={dmca} className="terms-iframe" />
    </Modal>
  );
};

export default DMCADialog;