import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useAuthContext } from "./AuthProvider";
import { bookmarkTierPrivilege } from "src/constants/bookmark";
import { message } from "antd";
import { activeDrawer } from "src/interface/PiqState";
import { useTrackerContext } from "./TrackerProvider";
import { trackEvents } from "src/constants/tracker";
import { deleteBookmark, getBookmarks, postBookmark } from "src/services/bookmark";
import { useLayoutContext } from "./LayoutProvider";
import { useNavigate } from "react-router-dom";


interface BookmarkContext {
    bookmarks: any;
    bookmarkClickHandler: (props: any) => void
    fetchingBookmarks: boolean;
    setShowBookmark: Dispatch<SetStateAction<boolean>>
    fetchMoreBookmark: () => void;
    showBookmark: boolean;
    isBookmarked: (slug: string) => boolean;
    hasMore: boolean;
}

export const BookmarkContext = React.createContext<BookmarkContext>({
    bookmarks: [],
    bookmarkClickHandler: () => { },
    fetchingBookmarks: false,
    setShowBookmark: () => { },
    fetchMoreBookmark: () => { },
    showBookmark: false,
    isBookmarked: () => false,
    hasMore: true
});

export const useBookmarkContext = () =>
    React.useContext(BookmarkContext);


export const BookmarkProvider: React.FC<{
    children: React.ReactNode;
}> = ({ children }) => {
    const { setActiveDrawer } = useLayoutContext()
    const [mounted, setMounted] = useState<boolean>(false);
    const [loading, setLoading] = useState(false)
    const [bookmarks, setBookmarks] = useState<any>([])
    const [page, setPage] = useState<number>(1);
    const [hasMore, setHasMore] = useState<boolean>(true);
    const [totalData, setTotalData] = useState(0);
    const PAGE_LIMIT = 50;
    const [showBookmark, setShowBookmark] = useState<boolean>(false);
    const { isSignedIn } = useAuthContext()
    const navigate = useNavigate();



    const { user_data } = useSelector((state: any) => {
        return {
            user_data: state.user_data.data
        }
    })

    const { sendEvent } = useTrackerContext();


    const subscriptionData = useSelector((state: any) => state.subscription);
    const subscriptionTier = useMemo(
        () => (!isSignedIn ? "low" : subscriptionData?.data?.active ? "high" : "mid"),
        [subscriptionData, isSignedIn]
    );

    const bookmarkClickHandler = async (data: any) => {
        const article_id = data?.article_id ?? data?._id
        const bookmarked: boolean = isBookmarked(article_id);

        if (!bookmarked) {
            if (bookmarks?.length === bookmarkTierPrivilege[subscriptionTier]) {
                message.warning(subscriptionTier === "low" ? "Please login to use this functionality." : subscriptionTier === "mid" ? "Bookmark limit reached. Upgrade to increase limit." : "Bookmark Limit reached", 10)
                if (subscriptionTier === "low") navigate("/login");
                return;
            }
        }

        const payload = {
            slug: data?.uniqueSlug ?? data?.slug,
            canonicalUrl: data?.canonicalUrl ?? "",
            fullContent: data?.fullContent ?? "",
            pubDate: data?.pubDate,
            user_id: user_data?._id,
            article_id: article_id,
            title: data?.title ?? "",
            sourceName: data?.sourceName,
            type: data?.type ?? data?.sourceName
        }

        const trackerPayload = {
            slug: payload?.slug,
            id: payload?.article_id
        }

        if (!bookmarked) {
            sendEvent({
                eventName: trackEvents.BOOKMARK_ADD_BTN,
                payload: trackerPayload
            })
            setBookmarks((prev: any) => ([
                payload,
                ...prev
            ]))
        } else {
            sendEvent({
                eventName: trackEvents.BOOKMARK_REMOVE_BTN,
                payload: trackerPayload
            })
            setBookmarks((prev: any) => {
                return [
                    ...prev?.filter((it: any) => {
                        const idToCompare = it?.article_id ?? it?._id
                        return idToCompare !== article_id
                    }),
                ]
            })
        }

        try {
            const res = bookmarked ? await deleteBookmark({
                article_id: article_id
            }) : await postBookmark(payload);

            message.success(!bookmarked ? "Article bookmarked successfully" : "Article removed from bookmark", 10)
        } catch (err) {
            message.error(!bookmarked ? "Failed adding to bookmark" : "Failed removing from bookmark", 10)
            if (!bookmarked) {
                setBookmarks((prev: any) => {
                    return [
                        ...prev?.filter((it: any) => {
                            const idToCompare = it?.article_id ?? it?._id
                            return idToCompare !== article_id
                        }),
                    ]
                })
            } else {
                setBookmarks((prev: any) => {
                    return [
                        payload,
                        ...prev
                    ]
                })
            }

        }
    }

    const fetchMoreBookmark = async () => {
        setPage(page + 1);
        setHasMore(true);
    };


    const fetchBookmarks = () => {
        getBookmarks(page, PAGE_LIMIT).then(res => {
            const data = res?.data;
            if (res?.data?.length === 0) {
                setHasMore(false)
            }
            setBookmarks(res?.data?.reverse())
            setHasMore(false)
            // setBookmarks([])
        }).catch(err => {
            console.log("Error = ", err)
        })
    }

    useEffect(() => {
        if ((!mounted || showBookmark) && isSignedIn) {
            setMounted(true)
            fetchBookmarks()
        } else if (!isSignedIn) {
            setBookmarks([]);
            setHasMore(false)
        }
    }, [showBookmark, isSignedIn])

    const isBookmarked = (article_id: string) => {
        return bookmarks?.some?.((it: any) => {
            const id_to_compare = it?.article_id ?? it?._id;

            return id_to_compare === article_id;
        })
    }

    return (
        <BookmarkContext.Provider
            value={{
                bookmarks,
                bookmarkClickHandler,
                fetchMoreBookmark,
                fetchingBookmarks: loading,
                showBookmark,
                setShowBookmark,
                isBookmarked,
                hasMore
            }}
        >
            {children}
        </BookmarkContext.Provider>
    );
};




