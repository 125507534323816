import { z } from "zod";
const PASSWORD_REGEX =
  /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>]).{8,20}$/;

export const loginSchema = z.object({
  email: z
    .string({ required_error: "", invalid_type_error: "" })
    .email({ message: "" }),
  password: z.string({ required_error: "", invalid_type_error: "" }),
});

export const forgotPasswordSchema = z
  .object({
    password: z
      .string({ invalid_type_error: " ", required_error: " " })
      .min(8, { message: " " })
      .max(20, { message: " " }),
    confirmPassword: z.string(),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: "Passwords don’t match. Please try again",
    path: ["confirmPassword"],
  });

export type TLoginSchema = z.infer<typeof loginSchema>;
export type TForgotPasswordSchema = z.infer<typeof forgotPasswordSchema>;
