import { Icon } from "@iconify/react";
import React from "react";
import { Link } from "react-router-dom";
import Button from "../../Button/Button";

interface PricingCardProps {
    monthly: boolean
    monthlyPrice: string;
    annualPrice: string;
    services: {
        title: string;
        checked: boolean
    }[];
    description: string;
    title: string;
    highlight: boolean;
    buttonText: string;
    redirectLink: string;
    saving?: string;
}

const PricingCard = ({
    monthly,
    monthlyPrice,
    annualPrice,
    services,
    description,
    title,
    highlight,
    buttonText,
    redirectLink,
    saving
}: PricingCardProps) => {



    return <div className={`pricing-card ${highlight ? "highlight" : ""}`}>
        <div className="pricing-card__header">
            <h4>{title}</h4>
            <div className="price">
                <h2>
                    £{monthly ? monthlyPrice : annualPrice}
                </h2>
                <div className="price-info">
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                        {monthly ? <span>per month</span> : <>
                            <span>per annum</span>
                            <span>{saving}</span>
                        </>}
                        {/* {
                            monthly ? "per month" : "per annum"
                        } */}
                    </div>
                </div>
            </div>
            <div className="button">
                <Link to={redirectLink}  >
                    <Button text={buttonText} type="primary" radius="lg" />
                </Link>
            </div>
            {/* <p>
                Dictum aliquet arcu egestas massa sed tellus sed arcu velit tincidunt orci.
            </p> */}
        </div>
        <div className="pricing-card__main">
            <ul>
                {
                    services?.map((item) => (
                        <li>
                            <span>
                                {item?.title}
                            </span>
                            <span>
                                {item?.checked ? <Icon icon="tabler:circle-check-filled" className="checkIcon" /> : <Icon icon="uis:times-circle" className="crossIcon" />}
                            </span>
                        </li>
                    ))
                }
            </ul>
        </div>
        {/* <div className="pricing-card__footer">

        </div> */}
    </div>;
};

export default PricingCard;
