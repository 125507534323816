export const enum trackEvents {
  ARTICLE_LIMIT_SIGNUP_BTN = "ALIMIT_SU_BTN",
  INPUT_FIELD_COMPLETE = "INPUT_FIELD_COMPLETE",
  SIGNUP_WITH_EMAIL_BTN_CLICK = "S_U_WITH_EMAIL_BTN",
  SIGNUP_TC = "S_U_TC",
  SIGNUP_EMAIL_UPDATES = "S_U_EU",
  SIGNUP_SUBMIT_BTN = "S_U_BTN",
  SIGNUP_SUCCESS = "SIGNUP_SUCCESS",

  AD_CLICK = "AD_CLICK",

  HEADER_BOOKMARK_CLICK = "HDR_BK_BTN",

  BOOKMARK_ADD_BTN = "BK_AD_BTN",
  BOOKMARK_REMOVE_BTN = "BK_RM_BTN",

  CLICK_GLOBAL_SEARCH_FIELD = "HDR_SEARCH_BTN",
  GLOBAL_SEARCH_QUERY = "HDR_SEARCH_QRY",

  FEED_SEARCH_BTN = "FD_HDR_SEARCH_BTN",
  FEED_REFRESH_BTN = "FD_HDR_RLOAD_BTN",
  FEED_FILTER_BTN = "FD_HDR_FLTR_BTN",
  FEED_SETTING_BTN = "FD_HDR_ST_BTN",
  FEED_FILTER_APPLY = "FD_ST_APLY_FLTR",
  FEED_SCROLL = "FD_SCRL",
  FEED_SESSION_TIME = "FD_SES_TIME",
  FEED_CHANGE = "FD_ST_CNG_FEED",
  FEED_SETTING_CLOSE = "FEED_ST_CLS",

  REGISTER_DRAWER_OPEN = "R_D_O",
  SIGN_IN_DRAWER_OPEN = "S_I_D_O",
  RESET_PASSWORD_DRAWER_OPEN = "R_P_D_O",
  REGISTER_DRAWER_CLOSED = "R_D_C",
  RESET_PASSWORD_DRAWER_CLOSED = "R_P_D_C",
  SIGN_IN_DRAWER_CLOSED = "S_I_D_C",

  ACTIVE_SESSION = "ACTIVE_SESSION",

  LOGIN_FAIL = "LGN_FAIL",
  LOGIN_SUCCESS = "LGN_SUCC",

  SIGN_IN_SUBMIT = "S_I_SUBMIT",

  FEED_ADD = "APS_FD_AD",
  FEED_REMOVE = "APS_FD_RM",

  FEED_HAS_KEYWORD = "FD_ST_HAS_KYWRD",

  // app selector
  APP_SELECTOR_SEARCH_ACTIVE = "AP_SEL_SRCH_ACTIVE",
  APP_SELECTOR_SEARCH = "AP_SEL_SRCH",
  APP_SELECTOR_TOOLTIP_HOVER = "AP_SEL_TLTIP",
  APP_SELECTOR_TAB_SCROLL = "AP_SEL_SCRL",
  APP_SELECTOR_TAB_CATEGORY = "AP_SEL_CAT",
  APP_SELECTOR_TAB_FILTER = "AP_SEL_FLTR",
  APP_SELECTOR_APP_SELECT = "AP_SEL_APP_SELECT",

  ARTICLE_VIEWER = "ARTCL_VIEWER",
  ARTICLE_POPUP = "ARTCL_POPUP",

  HIGH_IQ_PAYMENT_SUCCESS = "HI_IQ_SUCC",
  HIGH_IQ_PAYMENT_FAIL = "HI_IQ_FAIL",
  HIGH_IQ_SUB_TC = "HI_IQ_TC",
  HIGH_IQ_AD_PRMO_BUTTON = "HI_IQ_AD_PRMO_BTN",
  HIGH_IQ_PROMO_CODE_APPLY = "HI_IQ_PRMO_CODE_APPLY",
  GOOGLE_AUTH = "GOGL_AUTH",

  FREE_IQ_UPGRADE_RADIO_BTN = "FRE_IQ_RADIO_BTN",
  HIGH_IQ_UPGRADE_RADIO_BTN = "HI_IQ_RADIO_BTN",
  ACCOUNT_UPGRADE_MONTHLY_BTN = "ACC_UP_MON_ BTN",
  ACCOUNT_UPGRADE_YEARLY_BTN = "ACC_UP_YEA_BTN",
  UPGRADE_BTN = "HI_IQ_UPG_BTN",
  EMAIL_ME_PIQ_NEWS_BOX = "PIQ_NU_BTN",
  HI_IQ_PAY_NOW_BTN = "HI_IQ_PAY_NOW_BTN",
  HI_IQ_PROMO_CODE_WRITE = "HI_IQ_PROMO_CODE_WRITE",
  HI_IQ_DOWNGRADE_BTN = "HI_IQ_DG_BTN",
}
