import { zodResolver } from "@hookform/resolvers/zod";
import { Auth } from "aws-amplify";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { registerEmailVerificationFormSchema } from "src/constants/auth";
import { popMessage } from "src/development/Component/message/message";
import { edituser } from "src/store/User/action";
import { axiosInstance } from "src/store/utility";


export const useAuthEmailVerification = ({ withCheckout, nextStep, email }: any) => {
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation()
    const isLoginPage = location.pathname === '/login'

    const userDetail = useSelector((state: any) => state?.user_data?.data
    );

    const { formState: { errors } } = useForm({
        resolver: zodResolver(registerEmailVerificationFormSchema)
    })

    // const emailVerificationCodeData = watch();

    const formSubmitHandler = async (e: any) => {
        return new Promise(async (resolve, reject) => {
            try {
                // await initiateSignUp(registerFormData)
                return resolve("success")
            } catch (err) {
                return reject("failed")
            }
        })
    }

    const handleResendEmailVerificationButton = async () => {
        setError(false);
        try {
            if (isLoginPage) {
                await Auth.resendSignUp(email).then(() => {
                    popMessage.successMessage("Verification code sent successfully");
                })
            } else {
                await Auth.verifyCurrentUserAttribute("email").then(() => {
                    popMessage.successMessage("Code sent successfully.");
                });
            }
        } catch (error: any) {
            popMessage.errorMessage(error?.message || "Something went wrong while sending otp");
        }
    };

    const handleCodeSubmitUnverified = async (code: string) => {
        setSubmitting(true)
        try {
            if (isLoginPage) {
                // await Auth.confirmSignUp(email, code)
                await axiosInstance.post('/auth/verify-code', { username: email, code })
            } else {
                await Auth.verifyCurrentUserAttributeSubmit("email", code).then(
                    async () => {
                        dispatch(
                            edituser(
                                {
                                    ...userDetail,
                                    isVerified: true,
                                },
                                async (res: any) => {
                                    if (res.status === 200) {
                                        // if (!isCheckout) {
                                        //     setActiveDrawer("");
                                        // }
                                        // setAuthCode('')
                                        await axiosInstance.post('/auth/verify-sucess-mail', { username: userDetail.email })
                                        if (!withCheckout) {
                                            popMessage.successMessage("Thanks for registering");
                                        }
                                        setSubmitting(false)
                                    } else {
                                        popMessage.errorMessage("Something Went Wrong");
                                        setSubmitting(false)
                                    }
                                },
                                (err: any) => {
                                    // popMessage.errorMessage(err?.message);
                                    setSubmitting(false)
                                    throw err;

                                }
                            ) as any
                        );
                    }
                );
            }
        } catch (error: any) {
            console.log(error)
            // popMessage.errorMessage(error?.message);
            setSubmitting(false)
            throw error;
        }
    };

    const otpSubmitHandler = async (otp: string) => {
        setSubmitting(true);
        try {
            await handleCodeSubmitUnverified(otp);
            setSubmitting(false)
            if (withCheckout) {
                nextStep()
            } else {
                localStorage.setItem("loggedIn", "true");
                navigate("/");
            }
        } catch (err) {
            setSubmitting(false)
            setError(true);
        }
    }

    // console.log("form data = ", registerFormData);
    // console.log("form errors = ", errors)

    return { formSubmitHandler, handleResendEmailVerificationButton, emailOtpSubmitting: submitting, otpError: errors?.email || error, otpSubmitHandler, setOtpError: setError }
}