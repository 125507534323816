import React from 'react'
import { openNewWindow } from 'src/utils/common'

const ImageComponent: React.FC<{ item: any }> = ({ item }) => {

    console.log(item?.image, item?.title, 'item')
    return (
        <>
            {
                item?.image && (
                    item?.image.toLowerCase().endsWith('.mp3') ? (
                        <audio
                            controls
                            controlsList="play pause volume nodownload noplaybackrate noseek"
                            style={{
                                width: '100%',
                                height: '32px',
                                borderRadius: '4px',
                                cursor: 'pointer',
                                backgroundColor: '#f5f5f5',
                                boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                                outline: 'none',
                                border: '1px solid #ddd',
                                transition: 'height 0.3s ease',
                                fontSize: '12px' // Added smaller font size for icons
                            }}
                            src={item?.image}

                        />
                    ) : (
                        <img
                            className="cursor-pointer img-bg-center"
                            src={item?.image}
                            alt="PiQSuite.com"
                            style={{
                                borderRadius: 2,
                                maxHeight: 150,
                                cursor: "pointer"
                            }}
                            width={"100%"}
                            height="auto"
                            onClick={() => item?.canonicalUrl && openNewWindow(item?.canonicalUrl)}
                        />
                    )
                )

            }


        </>
    )
}

export default ImageComponent