import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { feedAxiosInstance } from "../utility";

const initialState = {
  error: "",
  loading: false,
  partnerfeeds: [],
  rssfeeds: [],
  piqnewsfeeds: [],
  premiumfeeds: [],
  socialapps: [],
  twitterfeeds: [],
  chartfeeds: [],
  columns: [],
  customFeeds: [],
  deleteModal: false,
  tutorialfeeds: [],
  polymarketWidgets: [],
  calendarWidgets: [],
};

export const fetchpiqcolumnsData: any = createAsyncThunk(
  "piqcolumn/piqcolumnsData",
  async () => {
    const res = await feedAxiosInstance.get(`piq-columns/w`);
    return res.data.data;
  }
);
export const fetchCustomFeedUserData: any = createAsyncThunk(
  "customfeed/customFeedData",
  async () => {
    const res = await feedAxiosInstance.get(`customfeed`);
    return res.data;
  }
);

export const piqcolumnsSlice = createSlice({
  name: "piqcolumns",
  initialState,
  reducers: {
    updatePiQColumnsData: (state: any, action: any) => {
      state.data = action.payload;
    },
    updateDeleteModal: (state: any, action: any) => {
      state.deleteModal = action.payload;
    },
    updateCustomFeedData: (state: any, action: any) => {
      state.customFeeds = action.payload;
    },
    resetPiQColumnsData: (state: any, action: any) => {
      state.data = [];
    },
    updateTitleBySlug: (state: any, action: any) => {
      const { slug, title } = action.payload;
      const feedIndex = state.customFeeds.findIndex(
        (feed: any) => feed.slug === slug
      );

      if (feedIndex !== -1) {
        state.customFeeds[feedIndex] = {
          ...state.customFeeds[feedIndex],
          title: title,
        };
      }
    },
    updateSlugsBySlug: (state: any, action: any) => {
      const { slug, slugs } = action.payload;
      const feedIndex = state.customFeeds.findIndex(
        (feed: any) => feed.slug === slug
      );

      if (feedIndex !== -1) {
        state.customFeeds[feedIndex] = {
          ...state.customFeeds[feedIndex],
          slugs: slugs,
        };
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchpiqcolumnsData.pending, (state) => {
        // state.data = premiumDataDetails
        state.loading = true;
      })
      .addCase(fetchpiqcolumnsData.fulfilled, (state, action) => {
        // console.log(action?.payload, "piqcolumns");
        state.columns = action?.payload;
        state.partnerfeeds = action?.payload?.filter((item: any) => {
          return item?.appSelectorTypes === "PARTNER_FEED";
        });
        state.piqnewsfeeds = action?.payload?.filter((item: any) => {
          return item?.appSelectorTypes === "PIQ_FEED";
        });
        state.premiumfeeds = action?.payload?.filter((item: any) => {
          return item?.appSelectorTypes === "PREMIUM_FEED";
        });
        state.socialapps = action?.payload?.filter((item: any) => {
          return item?.appSelectorTypes === "SOCIAL_APP_FEED";
        });
        state.twitterfeeds = action?.payload?.filter((item: any) => {
          return item?.appSelectorTypes === "TWITTER_FEED";
        });
        state.chartfeeds = action?.payload?.filter((item: any) => {
          return item?.appSelectorTypes === "CHART_FEED";
        });
        state.tutorialfeeds = action?.payload?.filter((item: any) => {
          return item?.appSelectorTypes === "TUTORIAL_FEED";
        });
        state.polymarketWidgets = action?.payload?.filter((item: any) => {
          return item?.appSelectorTypes === "PREDICTION_MARKETS";
        });
        state.calendarWidgets = action?.payload?.filter((item: any) => {
          return item?.appSelectorTypes === "CALENDAR";
        });
        state.rssfeeds = action?.payload?.filter((item: any) => {
          return item?.appSelectorTypes === "RSS_FEED";
        });

        localStorage.setItem("piqcolumns", JSON.stringify(action?.payload));

        state.loading = false;
      })
      .addCase(fetchpiqcolumnsData.rejected, (state, action) => {
        state.columns = [];
        state.error = "Something went wrong";
        state.loading = false;
      })
      .addCase(fetchCustomFeedUserData.pending, (state) => {
        // state.data = premiumDataDetails
        // state.loading = true;
      })
      .addCase(fetchCustomFeedUserData.fulfilled, (state, action) => {
        state.customFeeds = action?.payload;
      })
      .addCase(fetchCustomFeedUserData.rejected, (state, action) => {
        state.customFeeds = [];
      });
  },
});

export const {
  updatePiQColumnsData,
  resetPiQColumnsData,
  updateCustomFeedData,
  updateTitleBySlug,
  updateSlugsBySlug,
  updateDeleteModal,
} = piqcolumnsSlice.actions;
export default piqcolumnsSlice.reducer;
