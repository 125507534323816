import React, { useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import { debounce } from "lodash";
import TextInput from "../../TextInput/TextInput";
import { UseFormReturn } from "react-hook-form";
import { TLoginSchema } from "src/schema/auth/auth.schema";
import Button from "../../Button/Button";
import { useStepper } from "src/context/StepperProvider";
import { EMAIL_REGEX, PASSWORD_REGEX } from "src/constants/auth";
import { axiosInstance } from "src/store/utility";
import axios from "axios";
import { TermsConditionPrivacyPolicy } from "../Terms&Conditions";
import BottomAction from "../BottomAction/BottomAction";
import PasswordValidityInfo from "../../PasswordValidityInfo/PasswordValidityInfo";
import BackButton from "../BackButton";

const LoginForm = ({
  form,
  showPasswordFields = false,
  onSubmit,
  emailError = "",
}: {
  form: UseFormReturn<Partial<TLoginSchema>>;
  showPasswordFields?: boolean;
  onSubmit: any;
  emailError?: string;
}) => {
  const {
    handleSubmit,
    register,
    watch,
    formState: { errors, isSubmitting },
  } = form;


  const { setShowStepper } = useStepper();
  const [hasEmailValidated, setEmailValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const email = watch("email");
  const isEmailValid = email?.match(EMAIL_REGEX);
  const isPasswordValid = watch("password")?.length! > 0;

  const disabled =
    showPasswordFields
      ? !isEmailValid || !isPasswordValid || !hasEmailValidated
      : !isEmailValid || !hasEmailValidated;

  const validateEmail = async (emailToValidate: string) => {
    setLoading(true);
    setEmailValidated(false);
    try {
      await axiosInstance.post("/auth/check-email-verification", { email: emailToValidate });
      setError("");
      setEmailValidated(true);
    } catch (err) {
      const isAxiosError = axios.isAxiosError(err);

      if (isAxiosError) {
        const status = err.response?.status;
        switch (status) {
          case 404:
            setError("signup");
            break;
          case 403:
            setError("");
            setEmailValidated(true)
            break;
          default:
            setError("An unexpected error occurred. Please try again.");
        }
      } else {
        setError("An unexpected error occurred. Please try again.");
      }
    } finally {
      setLoading(false);
    }
  };

  const debouncedValidateEmail = useCallback(
    debounce((email: string) => validateEmail(email), 300),
    []
  );

  useEffect(() => {
    if (!email) {
      // Reset error if the email input is cleared
      setError("");
      setLoading(false);
      setEmailValidated(false);
      return;
    }

    if (isEmailValid) {
      debouncedValidateEmail(email);
    }

    // Cleanup function to cancel pending debounced calls when component unmounts
    return () => {
      debouncedValidateEmail.cancel();
    };
  }, [email, debouncedValidateEmail]);


  return (
    <>
      <h1 className="login-page-title">Sign In</h1>
      {/* <button className="sign-up-with-google-btn">
        <img src="/auth/google-logo.svg" alt="Google Logo" />
        Sign In with Google
      </button>

      <div className="or" style={{ display: "flex", alignItems: "center", gap: "12px" }}>
        Or
        <div className="line" />
      </div>

      <h1 className="login-page-subtitle">Sign In with Email</h1> */}
      <form onSubmit={handleSubmit(onSubmit)} className="form">
        <TextInput
          disabled={showPasswordFields}
          placeholder="example@gmail.com"
          padding="11px 16px"
          radius="md"
          onFocus={() => setShowStepper(true)}
          error={errors?.email?.message || emailError || error}
          iconString={hasEmailValidated ? "tabler:circle-check-filled" : "uis:times-circle"}
          showIcon={Boolean(isEmailValid)}
          {...register("email")}
          loading={loading}
          handleError={() => form.setValue("email", '')}
        />
        {
          error === "signup" && <span className="error">
            We don't recognise this email address. Try another or <Link to="/register" style={{
              fontWeight: "600",
            }}>Sign up</Link> to register.
          </span>
        }

        {showPasswordFields && (
          <>
            <TextInput
              type="password"
              placeholder="Password"
              padding="11px 16px"
              radius="md"
              error={errors?.password?.message}
              {...register("password")}
              handleError={() => form.setValue("password", '')}
              autoFocus={true}
            />
            <Link
              onClick={() => setShowStepper(false)}
              to={`/forgot-password?email=${encodeURIComponent(form.getValues("email")!)}`}
              style={{ marginLeft: "auto", color: "#4F48E2", fontWeight: 500 }}
            >
              Forgot Password?
            </Link>
          </>
        )}


        <div style={{ display: "flex", gap: 8 }}>
          {showPasswordFields && <BackButton disabled={isSubmitting} />}
          <Button
            disabled={disabled}
            loading={isSubmitting}
            className="submit-button"
            text="Continue"
            type="primary"
            height="38px"
            width="102px"
            weight="bold"
            radius="md"
          />
        </div>
        <TermsConditionPrivacyPolicy />
      </form>
      <BottomAction />

    </>
  );
};

export default LoginForm;