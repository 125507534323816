import React, { useContext } from "react";
import { useSelector } from "react-redux";

import menyIcon from "src/style/images/Menu Icon.svg";
import feed_search_icon from "src/style/images/feed_search_icon.svg";
import { SocketNames } from "src/interface/PiqState";
import { RefreshButtoneComponent } from "../RefreshButton/inde";
import { useAuthContext } from "src/context/AuthProvider";
import {
  IndividualFeedContext,
} from "src/context/IndividualFeedProvider";
import filter_icon from "src/style/images/filter-icon.svg";
import filter_active from "src/style/images/filter_active.svg";
import search_active from "src/style/images/search_active.svg";
import { hideFilterOptionsComponents } from "src/constants/filter";
import { trackEvents } from "src/constants/tracker";
import { useTrackerContext } from "src/context/TrackerProvider";
import { customFeedsSockets } from "src/SuiteConfig/columnConfiguration";
import { runSocketConnect } from "src/Socket";

interface ColumnHeaderProps {
  navImg?: any;
  title: string;
  isFetching?: boolean;
  fetchFunction?: () => any;
  handleEditPanel: (params: any) => any;
  socket?: SocketNames;
  handleSearch?: (params: any) => any;
  toggleFilter?: () => void;
  searchedText?: string;
}

const ColumnHeader = ({
  title,
  fetchFunction,
  handleEditPanel,
  isFetching,
  socket,
  handleSearch,
  toggleFilter,
  searchedText,
}: ColumnHeaderProps) => {
  const darkMode = useSelector((state: any) => state?.user?.darkMode);
  const { sendEvent } = useTrackerContext()

  const handelReload = () => {
    sendEvent({
      eventName: trackEvents.FEED_REFRESH_BTN,
      payload: {
        feed_name: socket
      }
    })
    fetchFunction && fetchFunction();
  };
  const { isSignedIn: isUserLoggedIn } = useAuthContext();
  const { filterDisabled, colFilterConfig, emptyFilter } = useContext(
    IndividualFeedContext
  );
  const { user }: any = useAuthContext();


  return (
    <div className={`v2-column-header`}>
      <div className="pointer title-container"
      >
        <span className="v2-column-header-text   grid-drag-handle">
          {title}
        </span>
        <RefreshButtoneComponent
          fetchFunction={() => {
            sendEvent({
              eventName: trackEvents.FEED_REFRESH_BTN,
              payload: {
                feed_name: socket
              }
            })
            handelReload()
          }}
          isFetching={isFetching}
        />
        <div className="grid-drag-handle pointer">
          <div></div>
        </div>
      </div>
      <div className="grid-drag-handle pointer filler" >
      </div>
      <div
        style={{
          display: "flex",
          width: "100%",
          // justifyContent: "end",
          alignItems: "center",
        }}
        className=""
      >
        <div className="grid-drag-handle pointer filler"></div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "2px"
          }}
        >
          {!hideFilterOptionsComponents.includes(socket!) && !customFeedsSockets.some(feed => socket?.includes(feed)) ? (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                if (handleSearch) handleSearch(false);
              }}
              className="v2-column-header-option-icon"
            >
              {searchedText && searchedText !== "" ? (
                <img
                  style={{
                    filter: "none",
                  }}
                  src={search_active}
                  alt="||"
                />
              ) : (
                <img
                  src={feed_search_icon}
                  alt="||"
                  className={`${darkMode ? "inverted" : ""
                    }`}
                />
              )}
            </span>
          ) : (
            <></>
          )}
          {!hideFilterOptionsComponents.includes(socket!) && (
            <>
              {/* filter icon */}
              <span
                style={{ cursor: "pointer" }}
                className={`${filterDisabled ? "disabled" : ""} v2-column-header-option-icon`}
                onClick={(e) => {
                  e.stopPropagation();
                  if (filterDisabled) return;
                  if (toggleFilter) toggleFilter();

                }}
              >
                {colFilterConfig?.is_active &&
                  !emptyFilter &&
                  !filterDisabled ? (

                  <img
                    style={{
                      filter: "none",
                    }}
                    src={filter_active}
                    alt="||"
                    className={` active`}
                  />
                ) : (
                  <img
                    src={filter_icon}
                    alt="||"
                    className={` ${darkMode ? "inverted" : ""
                      }`}
                  />
                )}
              </span>
            </>
          )}
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              handleEditPanel(false);
            }}
          >
            <img
              src={menyIcon}
              alt="||"
              className={`v2-column-header-option-icon ${darkMode ? "inverted" : ""
                }`}
            />
          </span>
        </div>
      </div>
    </div >
  );
};

export default ColumnHeader;
