import React, { Dispatch, SetStateAction, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { cryptoWidgetConfig } from 'src/constants/charts';

const CryptoComparePricesWidget: React.FC<{
    reload: boolean;
    setFetching: (fetching: boolean) => void;
    setReload: Dispatch<SetStateAction<boolean>>;
}> = ({ reload, setFetching, setReload }) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const darkMode = useSelector((state: any) => state?.user?.darkMode);

    useEffect(() => {
        const loadCryptoComparePrices = () => {
            if (!containerRef.current) return; // Ensure the container exists

            const baseUrl = 'https://widgets.cryptocompare.com/';
            const appName = 'local';
            const pricesUrl = `${baseUrl}serve/v1/coin/multi?fsyms=BTC,ETH,ZEC,LTC,DASH,DOGE,XRP,XMC&tsyms=USD,EUR,GBP&app=${appName}`;

            // Create a script element
            const script = document.createElement('script');
            script.src = pricesUrl;
            script.type = 'text/javascript';
            script.async = true;
            script.innerHTML = cryptoWidgetConfig(darkMode);

            // Append the script to the container
            if (containerRef.current) {
                containerRef.current.appendChild(script);
            }

            // Handle script load and error events
            script.onload = () => {
                setFetching(false);
                setReload(false);
            };

            script.onerror = () => {
                setFetching(false);
                setReload(false);
            };
        };

        const cleanUp = () => {
            if (containerRef.current) {
                containerRef.current.innerHTML = ''; // Clear any existing elements
            }
        };

        // Clean up before loading new script
        cleanUp();
        loadCryptoComparePrices();

        return () => {
            cleanUp(); // Cleanup on unmount or reload
        };
    }, [darkMode, reload, setFetching, setReload]);

    return (
        <div
            className={`widget-container tradingview-widget-container ${darkMode ? 'dark' : ''}`}
            ref={containerRef}
            style={{ height: '100%', backgroundColor: 'white' }}
        >
            <div className="tradingview-widget-container__widget"></div>
        </div>
    );
};

export default CryptoComparePricesWidget;
