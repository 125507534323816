import "./ForgotPassword.scss";
import React, { useState } from "react";
import PasswordResetVerification from "../PasswordResetVerification/PasswordResetVerification";
import { ChangePassword } from "../ChangePassword/ChangePassword";
import BottomAction from "../BottomAction/BottomAction";

const ForgotPasswordPage = () => {
  const [step, setStep] = useState(1);
  return (
    <>
      <div className="forgot-password-container">
        {step === 2 ? (
          <ChangePassword onBack={() => setStep(1)} />
        ) : (
          <PasswordResetVerification setStep={setStep} />
        )}
      </div>
      <BottomAction />
    </>
  );
};

export default ForgotPasswordPage;
