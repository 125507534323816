import React, {
  createContext,
  useState,
  ReactNode,
  useContext,
  useEffect,
  Dispatch,
  SetStateAction,
} from "react";
import { useLocation } from "react-router-dom";

// Create a context for the Stepper
interface StepperContextType {
  showStepper: boolean;
  setShowStepper: Dispatch<SetStateAction<boolean>>;
  setSteps: Dispatch<SetStateAction<string[]>>;
  steps: string[];
}

const StepperContext = createContext<StepperContextType | undefined>(undefined);

interface StepperProviderProps {
  children: ReactNode;
}

// StepperProvider component to provide the showStepper state and its setter
export const StepperProvider: React.FC<StepperProviderProps> = ({
  children,
}) => {
  const [showStepper, setShowStepper] = useState<boolean>(false);
  const [steps, setSteps] = useState<string[]>([]);
  const location = useLocation();

  // Effect to set the initial state of showStepper based on the 'step' query param
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const stepParam = parseInt(searchParams.get("step") || "1", 10);

    // If the step is greater than 1, set showStepper to true
    if (stepParam > 1) {
      setShowStepper(true);
    }
  }, [location.search]);

  return (
    <StepperContext.Provider
      value={{ showStepper, setShowStepper, steps, setSteps }}
    >
      {children}
    </StepperContext.Provider>
  );
};

export const useStepper = (): StepperContextType => {
  const context = useContext(StepperContext);
  if (!context) {
    throw new Error("useStepper must be used within a StepperProvider");
  }
  return context;
};
