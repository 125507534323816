import React, { Dispatch, SetStateAction, useEffect, useRef } from 'react';

import { useSelector } from 'react-redux';
import { getTradingComponentScriptContent, tradingViewSocketToUrlMap } from 'src/constants/charts';
import { SocketNames } from 'src/interface/PiqState';

const TradingViewGenericWidget: React.FC<{
    reload: boolean;
    socket: SocketNames;
    setFetching: (fetching: boolean) => void;
    setReload: Dispatch<SetStateAction<boolean>>
}> = ({ socket, reload, setFetching, setReload }) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const darkMode = useSelector((state: any) => state?.user?.darkMode);

    useEffect(() => {
        const loadScript = () => {
            const script = document.createElement('script');
            script.src = tradingViewSocketToUrlMap?.get(socket)!;
            script.type = 'text/javascript';
            script.async = true;
            script.innerHTML = getTradingComponentScriptContent(socket, darkMode)!;

            if (containerRef.current) {
                containerRef.current.appendChild(script);
            }

            script.onload = () => {
                setFetching(false);
                setReload(false);
            };
        };

        // Remove existing script if any
        if (containerRef.current) {
            const existingScript = containerRef.current.querySelector('iframe');

            if (existingScript) {
                containerRef.current.removeChild(existingScript);
            }
        }

        // Load new script
        loadScript();

        return () => {
            if (containerRef.current) {
                const scriptElement = containerRef.current.querySelector('script');
                if (scriptElement) {
                    containerRef.current.removeChild(scriptElement);
                }
            }
        };
    }, [darkMode, reload]);

    return (
        <>
            <div className={`widget-container tradingview-widget-container ${darkMode ? "dark" : ""}`} ref={containerRef}
                style={{
                    height: '100%', // Desired height for container
                }}
            >
                <div className="tradingview-widget-container__widget"></div>
            </div>
            <div className="tradingview-widget-copyright" style={{ background: darkMode ? '#303742' : 'white', height: '50px', border: "0.5px solid #d9dadb", borderTop: "0px", marginLeft: 4 }}>
                <a href="https://www.tradingview.com/" rel="noreferrer" target="_blank"><span className="blue-text">Track all markets on TradingView</span></a>
            </div>
        </>
    );
};

export default TradingViewGenericWidget;
