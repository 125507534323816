import './register.scss'
import React, { useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import EmailVerification from '../EmailVerification/EmailVerification';
import Checkout from '../Checkout/Checkout';
import PaymentSuccessful from '../SuccessComponent/PaymentSuccessful';
import { addUrlParam, getUrlParam } from 'src/utils/utilFunction';
import RegisterForm from './RegisterForm';
import { useAuthEmailVerification } from 'src/Hooks/auth/useAuthEmailVerification';
import { useSelector } from 'react-redux';
import { useStepper } from 'src/context/StepperProvider';


const RegisterPage = () => {
    const [step, setStep] = useState<number>(1);

    const location = useLocation();
    const navigate = useNavigate();

    const { setShowStepper } = useStepper();

    const { user_data } = useSelector((state: any) => {
        return {
            user_data: state?.user_data?.data,
        };
    });

    const withCheckout = useMemo(() =>
        getUrlParam("with") === "checkout", [location])

    const stepFromParams = useMemo(() =>
        parseInt(getUrlParam("step") ?? "0")
        , [location?.search])
    const emailParam = getUrlParam("email")


    const nextStep = (): void => {
        const searchParams = addUrlParam('step', (step + 1)?.toString());
        navigate(`${location.pathname}?${searchParams}`)
    };

    const {
        handleResendEmailVerificationButton,
        emailOtpSubmitting,
        otpSubmitHandler,
        otpError,
        setOtpError
    } = useAuthEmailVerification({ withCheckout, nextStep });

    // console.log("Step = ", step, stepFromParams)

    useEffect(() => {
        if (stepFromParams !== 0) {
            setStep(stepFromParams);
            // setShowStepper(true)
        }
        else {
            // const searchParams = addUrlParam('step', step?.toString());
            // navigate(`${location.pathname}?${searchParams}`)
            setStep(1);
        }
    }, [stepFromParams])

    useEffect(() => {
        if (emailParam) {
            setShowStepper(true);
        }
    }, [emailParam])

    return (
        <>
            <div className="register-page-wrapper">
                {
                    step === 1 && <RegisterForm
                        setStep={setStep}
                        withCheckout={withCheckout}
                        nextStep={nextStep}
                    />
                }
                {step === 2 &&
                    <EmailVerification nextStep={nextStep} nextStepHandler={otpSubmitHandler}
                        resendOtpHandler={handleResendEmailVerificationButton}
                        submitting={emailOtpSubmitting}
                        error={!!otpError}
                        email={user_data?.email}
                        setError={setOtpError}
                        sendOtpByDefault={false}
                    />
                }
                {
                    withCheckout && step === 3 && <Checkout
                        nextStepHandler={() => {
                            nextStep();
                        }}
                    />
                }
                {
                    withCheckout && step === 4 && <div className="payment-success"><PaymentSuccessful
                    /></div>
                }
            </div>
        </>
    )
}

export default RegisterPage
