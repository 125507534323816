import React, { useEffect } from "react";
import "./AuthLayout.scss";
import Stepper from "src/development/Component/Auth/Stepper";
import { StepperProvider } from "src/context/StepperProvider";
import { useNavigate } from "react-router-dom";

interface AuthLayoutProps {
  children: React.ReactNode;
}

const AuthLayout = ({ children }: AuthLayoutProps) => {
  const navigate = useNavigate()

  useEffect(() => {
    const loggedIn = localStorage.getItem('loggedIn')
    if (loggedIn === 'true') navigate('/')
  }, [navigate])

  return (
    <div className="auth-layout-wrapper">
      <div className="left">
        <img
          src="/auth/layout.webp"
          alt="auth-layout-left"
          style={{ height: "100%", width: "100%", objectFit: "contain" }}
        />
      </div>
      <div className="right">
        <StepperProvider>
          <div className="stepper-wrapper">
            <Stepper />
          </div>
          {children}
        </StepperProvider>
      </div>
    </div>
  );
};

export default AuthLayout;
