import React, { Dispatch, FormEvent, SetStateAction, useEffect, useMemo, useRef, useState } from "react";
import TextInput from "../../TextInput/TextInput";
import { useLocation, useNavigate } from "react-router-dom";
import { useStepper } from "src/context/StepperProvider";
import PasswordValidityInfo from "../../PasswordValidityInfo/PasswordValidityInfo";
import { Link } from "react-router-dom";
import BottomAction from "../BottomAction/BottomAction";
import Button from "../../Button/Button";
import { useRegister } from "src/Hooks/auth/useNewRegister";
import ReCAPTCHA from "react-google-recaptcha";
import { addUrlParam, isValidEmailComprehensive } from "src/utils/utilFunction";
import Checkbox, { CheckboxChangeEvent } from "antd/lib/checkbox/Checkbox";
import BackButton from "../BackButton";

type Props = {
    setStep: SetStateAction<Dispatch<number>>
    withCheckout: boolean;
    nextStep: () => void;
};


const RegisterForm = ({
    withCheckout,
    nextStep
}: Props) => {
    const emailInputRef = useRef<HTMLInputElement>(null);
    const recaptchaRef = useRef<ReCAPTCHA>(null);
    const [showPasswordField, setShowPasswordField] = useState<boolean>(false);
    const [recaptcha, setRecaptcha] = useState<string>("");
    const { setShowStepper } = useStepper();

    const {
        register,
        registerFormSubmitHandler,
        errors,
        registerFormData,
        submitting,
        emailExistError,
        emailChangeHandler,
        passwordChangeHandler,
        disableEmail,
        checkingEmail,
        setValue,
        setEmailExistError
    } = useRegister({
        emailInputRef,
        withCheckout
    });

    const location = useLocation();
    const navigate = useNavigate();

    const searchParams = new URLSearchParams(location.search);
    const internalStep = parseInt(searchParams.get("internalStep") || "0", 10);
    const emailParam = searchParams.get("email")

    useEffect(() => {
        if (emailParam) {
            const decodedEmailParam = decodeURIComponent(emailParam);
            setValue("email", decodedEmailParam);
        }
    }, [emailParam])



    const disableFormSubmission =
        useMemo(() => showPasswordField ?
            (recaptcha === "" ||
                submitting ||
                // !registerFormData?.piqNu ||
                !!errors?.password ||
                registerFormData?.password === "" ||
                !registerFormData?.tc ||
                !registerFormData?.firstName ||
                !registerFormData?.lastName
            ) : (
                !!emailExistError ||
                !!errors?.email ||
                registerFormData?.email === "" ||
                !registerFormData?.email ||
                disableEmail
            ), [showPasswordField, registerFormData, errors])

    const submitHandler = async (e: FormEvent) => {
        e.preventDefault();
        if (disableFormSubmission) return;
        if (showPasswordField) {
            registerFormSubmitHandler(e).then(res => {
                // nextStep();
                const searchParams = addUrlParam('step', 2?.toString());
                navigate(`${location.pathname}?${searchParams}`)
            })
        } else {
            setShowPasswordField(true)
            const searchParams = new URLSearchParams(location.search);
            searchParams.set('internalStep', 1?.toString());
            searchParams.set('email', registerFormData?.email);
            navigate(`${location.pathname}?${searchParams.toString()}`)
        }
    }

    useEffect(() => {
        if (emailInputRef?.current?.value !== "") {
            if (internalStep !== 0) setShowPasswordField(true)
            else setShowPasswordField(false)
        }
    }, [internalStep, emailInputRef?.current])

    return <div className='register-page-container'>
        <h1 className='register-page-title'>Sign Up</h1>
        {/* <button className='sign-up-with-google-btn'>
            <img src="/auth/google-logo.svg" alt="" />
            Sign Up with Google</button>

        <div className='or' style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
            Or
            <div className='line' />
        </div>

        <h3 className='register-page-subtitle'>
            Sign Up with Email
        </h3> */}

        <form action="#" className='form' onSubmit={submitHandler}>
            <TextInput
                placeholder="example@gmail.com"
                padding="11px 16px"
                radius="md"
                onFocus={() => setShowStepper(true)}
                value={registerFormData?.email}
                disabled={(showPasswordField && internalStep !== 0) || submitting}
                {...register("email")}
                onChange={emailChangeHandler}
                error={emailExistError || errors?.email?.message as string}
                iconString={errors?.email || !!emailExistError ? "uis:times-circle" : isValidEmailComprehensive(registerFormData?.email) ? "tabler:circle-check-filled" : ""}
                ref={emailInputRef}
                showIcon={registerFormData?.email !== ""}
                loading={checkingEmail}
                handleError={() => {
                    setValue("email", '', { shouldValidate: false });
                    setEmailExistError("");
                    emailInputRef?.current?.focus();
                }}
            />
            {
                emailExistError === "AlreadyExists" && <span className="error">
                    This email already exists. Try another email or <Link to="/login" style={{
                        fontWeight: "600",
                        color: "#FF644E"
                    }}>Sign In</Link> to login with this email.
                </span>
            }
            {
                showPasswordField && internalStep !== 0 && <>

                    <div className="personal-info-container" >
                        <TextInput
                            padding="11px 16px"
                            radius="md"
                            placeholder="First Name"
                            value={registerFormData?.firstName}
                            {...register("firstName")}
                            autoFocus
                            error={errors?.firstName?.message as string}
                        />
                        <TextInput
                            padding="11px 16px"
                            radius="md"
                            placeholder="Last Name"
                            value={registerFormData?.lastName}
                            {...register("lastName")}
                            error={errors?.lastName?.message as string}
                        />
                    </div>

                    <TextInput
                        type="password"
                        value={registerFormData?.password}
                        placeholder="Password"
                        {...register("password")}
                        padding="11px 16px"
                        radius="md"
                        name="password"
                        onChange={passwordChangeHandler}
                        handleError={() => setValue("password", '')}

                    />
                    <PasswordValidityInfo password={registerFormData?.password} />
                    <div className="toc-container">
                        <ul>
                            <li>
                                <Checkbox
                                    style={{ position: "relative", top: '-3px' }}
                                    id="privacy-policy"
                                    {...register("tc")}
                                    onChange={(e: CheckboxChangeEvent) => setValue('tc', e.target.checked)}

                                />
                                <label htmlFor="privacy-policy">
                                    <span>
                                        I’ve read the
                                    </span>
                                    <Link to="/terms-and-conditions" target='_blank'>T&C’s</Link>&
                                    <Link to="/privacy-policy" target='_blank'>
                                        Privacy Policy
                                    </Link>
                                </label>
                            </li>
                            <li>
                                <Checkbox
                                    id="email-me"
                                    {...register("piqNu")}
                                    onChange={(e: CheckboxChangeEvent) => setValue('piqNu', e.target.checked)}
                                    style={{ position: "relative", top: '-3px' }}
                                />
                                <label htmlFor="email-me">Email me PiQ news & updates</label>
                            </li>
                        </ul>
                    </div>
                    <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey="6LdBRpgqAAAAACFs-iHTpr-3-7xoW6602cT-kb11"
                        onChange={(val) => setRecaptcha(val!)}
                    />

                </>
            }


            <div style={{ display: "flex", gap: 8 }}>
                {showPasswordField && <BackButton disabled={submitting} />}
                <Button
                    text="Continue"
                    type="primary"
                    disabled={disableFormSubmission}
                    loading={submitting}
                    height="38px"
                    width="102px"
                    weight="bold"
                    radius="md"
                />
            </div>
        </form>
        <BottomAction />

    </div >;
};

export default RegisterForm;
