import { Button, Collapse, message } from "antd";
import React, { Dispatch, SetStateAction, useContext } from "react";
import closeIcon from "src/style/images/close_icon.svg";
import { CloseCircleOutlined, SettingFilled, CopyOutlined } from "@ant-design/icons";
import EditPanel from "./EditPanel";
import { SocketNames } from "src/interface/PiqState";
import { CaretRightOutlined } from "@ant-design/icons";
import KeywordHighlight from "./KeywordHighlight";
import useColumnHeader from "src/Hooks/useColumnHeader";
import FilterPanel from "./FilterPanel";
import { IndividualFeedContext } from "src/context/IndividualFeedProvider";
import { trackEvents } from "src/constants/tracker";
import { useTrackerContext } from "src/context/TrackerProvider";
import { hideFilterOptionsComponents, showShareOptionsComponents } from "src/constants/filter";

const { Panel } = Collapse;
const ColumnHeaderOption: React.FC<{
  setShowEditPanel: (params: any) => any;
  socket: SocketNames;
  defaultActivePanel?: string;
}> = ({ setShowEditPanel, socket, defaultActivePanel }) => {
  const { removeColumn } = useColumnHeader();

  const { setOpenByDefault, sendFeedSesionDuration } = useContext(IndividualFeedContext);

  const { sendEvent } = useTrackerContext();

  return (
    <div
      className="v2-column-header-option"
    >
      <div style={{ textAlign: "right" }}>
        <Button
          type="text"
          shape="circle"
          size="small"
          className="v2-column-header-option-close-btn"
          icon={
            <img
              src={closeIcon}
              alt=""
              className="v2-column-header-option-close-btn-icon"
            />
          }
          onClick={() => {
            setShowEditPanel(false);
            setOpenByDefault("");
            sendEvent({
              eventName: trackEvents.FEED_SETTING_CLOSE,
              payload: {
                feed_name: socket
              }
            })
          }}
        />
      </div>

      {hideFilterOptionsComponents.includes(socket) ? (
        ""
      ) : (
        <>
          <div className="v2-edit-panel-setting-title-div">
            <div className="v2-edit-panel-setting-title">Feed Settings</div>
            <SettingFilled className="v2-edit-panel-setting-icon" />
          </div>

          <div className="settings-divider"></div>

          <Collapse
            accordion
            className="v2-edit-panel-collapse1"
            bordered={false}
            expandIconPosition={"end"}
            expandIcon={({ isActive }) => (
              <CaretRightOutlined rotate={isActive ? 90 : 0} />
            )}
            defaultActiveKey={defaultActivePanel ?? ""}
          >
            <Panel
              header="Keyword Alerts"
              key="1"
              style={{ border: "none", marginBottom: "5px", cursor: "pointer" }}
            >
              <KeywordHighlight socket={socket} />
            </Panel>
            <Panel
              header="Change Feed"
              key="2"
              style={{ border: "none", marginBottom: "5px", cursor: "pointer" }}
            >
              <EditPanel socket={socket} />
            </Panel>
            <Panel
              header="Conditional Filter"
              key="5"
              style={{ border: "none" }}
            >
              <FilterPanel
                socket={socket}
              />
            </Panel>
          </Collapse>

          <div className="settings-divider"></div>
        </>
      )}

      <div
        style={{ color: "red", cursor: "pointer", marginBottom: "10px" }}
        onClick={() => {
          sendFeedSesionDuration(socket);
          sendEvent({
            eventName: trackEvents.FEED_REMOVE,
            payload: {
              feed_name: socket
            }
          })
          removeColumn(socket)
        }}
      >
        <CloseCircleOutlined /> Delete Feed
      </div>
      {(!hideFilterOptionsComponents.includes(socket) || showShareOptionsComponents.includes(socket)) && <div
        style={{ color: "green", cursor: "pointer", marginBottom: "100px" }}
        onClick={() => {
          navigator.clipboard.writeText(
            `${window?.location?.origin}/feed/${socket}` || `https://piqsuite.com/feed/${socket}`
          );
          message.success("Link copied to clipboard", 10);
        }}
      >
        <CopyOutlined /> Share Feed
      </div>}
    </div>
  );
};

export default ColumnHeaderOption;
