import { Icon } from "@iconify/react";
import React from "react";
import "./PasswordValidityInfo.scss";

type Props = {
  password?: string;
};

const passwordConditions = [
  { text: "Between 8 to 20 characters", regex: /^.{8,20}$/ },
  { text: "1 upper case letter", regex: /[A-Z]/ },
  { text: "1 or more number", regex: /\d/ },
  { text: "1 or more special characters", regex: /[!@#$%^&*(),.?":{}|<>]/ },
];

const successIcon = (
  <Icon icon="tabler:circle-check-filled" className="success" />
);
const errorIcon = <Icon icon="uis:times-circle" className="error" />;
const defaultIcon = <Icon icon="mdi:circle-outline" className="default" />;

const PasswordValidityInfo = ({ password = "" }: Props) => {
  return (
    <div className="password-validity-info-container">
      <p className="title-text">Your password must contain</p>
      <ul>
        {passwordConditions.map((condition, idx) => {
          // Check if the condition is met
          const isValid = condition.regex.test(password);
          const icon = password
            ? isValid
              ? successIcon
              : errorIcon
            : defaultIcon;

          // Assign error class for invalid condition
          const listItemClass = password && !isValid ? "error" : "";

          return (
            <li key={idx} className={listItemClass}>
              {icon} {condition.text}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default PasswordValidityInfo;
