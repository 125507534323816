import { Stripe } from "@stripe/stripe-js/types/stripe-js";
import { Dispatch, FormEvent, SetStateAction, useEffect, useMemo, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { annuallyHighIqPrice, annualSalesTax, monthlyHighIqPrice, monthlySalesTax } from "src/constants/pricing";
import { fetchSubscriptionData } from "src/store/Subscriptions/subscriptionSlice";
import { check_if_token_is_expired } from "src/store/userStore";
import { axiosInstance } from "src/store/utility";
import { getUrlParam } from "src/utils/utilFunction";

const API_URL = process.env.REACT_APP_API_URL;

type Props = {
    elements: any;
    lm_data: any;
    stripe: Stripe | null;
    userData: any;
    setPaymentError: Dispatch<SetStateAction<boolean>>
    afterPaymentStepHandler: () => void;
}

const promoCodeErrorTxt = "The promocode you entered is invalid. Try another valid code."

const useCheckout = ({
    elements,
    lm_data,
    stripe,
    userData,
    setPaymentError,
    afterPaymentStepHandler
}: Props) => {

    const promoCodeRef = useRef<HTMLInputElement>(null);
    const [promoCodeSubmitting, setPromoCodeSubmitting] = useState<boolean>(false);
    const [discountAmount, setDiscountAmount] = useState<number>();
    const [discountPercent, setDiscountPercent] = useState<number>();
    const [promoCode, setPromoCode] = useState<string>("");

    const [paymentLoading, setPaymentLoading] = useState<boolean>(false);

    const [promoCodeError, setPromoCodeError] = useState<string>("");
    const [tc, setTc] = useState<boolean>(false);

    const monthlyPrice = monthlyHighIqPrice;
    const yearlyPrice = annuallyHighIqPrice;

    const [isMonthly, setIsMonthly] = useState<boolean>(false);

    const location = useLocation();
    const dispatch = useDispatch();

    const salesTax = useMemo(() => isMonthly ? monthlySalesTax : annualSalesTax, [isMonthly]);

    const subscriptionTypeFromParams = useMemo(() => {
        return getUrlParam("subscriptionType")
    }, [location?.search])

    useEffect(() => {
        if (subscriptionTypeFromParams) {
            if (subscriptionTypeFromParams === "monthly") setIsMonthly(true);
            else if (subscriptionTypeFromParams === "annually") setIsMonthly(false);
        }
    }, [subscriptionTypeFromParams])

    const handlePromoCode = async () => {
        const promoCode = promoCodeRef?.current?.value;
        if (promoCode?.trim() === "") return;
        try {
            const response: any = await axiosInstance.post(
                `${API_URL}/subscriptions/coupon/validation`,
                {
                    promoCode: promoCode?.trim(),
                    totalAmt: isMonthly
                        ? Number((monthlyPrice + salesTax).toFixed(2))
                        : Number((yearlyPrice + salesTax).toFixed(2)),
                }
            );

            if (response?.data?.status) {
                const totalAmt: number = isMonthly
                    ? Number((monthlyPrice + salesTax).toFixed(2))
                    : Number((yearlyPrice + salesTax).toFixed(2));


                if (response?.data?.data?.percent_off) {
                    const discountAmt = totalAmt * (response?.data?.data?.percent_off / 100);
                    console.table({ totalAmt, percent_off: response?.data?.data?.percent_off, amount_off: response?.data?.data?.amount_off, discountAmount: discountAmt })
                    setDiscountAmount(discountAmt);
                    setDiscountPercent(response?.data?.data?.percent_off);
                } else if (response?.data?.data?.amount_off) {
                    const discountAmt = response?.data?.data?.amount_off / 100;
                    const discountPercentage = ((discountAmt / totalAmt) * 100).toFixed(2);
                    console.table({ totalAmt, percent_off: discountPercentage, amount_off: response?.data?.data?.amount_off, discountAmount: discountAmt })
                    setDiscountAmount(discountAmt);
                    setDiscountPercent(Number(discountPercentage));
                }
            } else {
                setPromoCodeError(promoCodeErrorTxt);
                if (promoCodeRef.current) {
                    promoCodeRef.current.value = ""
                }
            }
        } catch (error) {
            console.log(error);
        }
    };

    const promoCodeSubmitHandler = async (e: FormEvent) => {
        setPromoCodeSubmitting(true);
        try {
            e.stopPropagation();
            e.preventDefault();
            await handlePromoCode();
            setPromoCodeSubmitting(false);
        } catch (err) {
            console.log("error = ", err)
            setPromoCodeSubmitting(false);
        }
    }

    const handlePay = async () => {
        if (!tc) {
            return;
        }
        setPaymentLoading(true);
        try {
            const { error: submitError }: any = await elements?.submit();
            if (submitError) {
                setPaymentError(submitError.message);
                setPaymentLoading(false);
                throw submitError;
            }

            const totalAmount = isMonthly
                ? Number((monthlyHighIqPrice + monthlySalesTax - (discountAmount || 0)).toFixed(2))
                : Number((annuallyHighIqPrice + annualSalesTax - (discountAmount || 0)).toFixed(2));

            const payload = {
                type: isMonthly ? "Monthly" : "Yearly",
                promoCode: promoCode,
                totalAmt: totalAmount,
                lm_data,
            }

            const res = await axiosInstance.post(
                `${API_URL}/subscriptions/create/sub`,
                payload
            );

            if (res?.data?.message) {
                setPaymentError(res?.data?.message);
                toast.error(res?.data?.message);
                setPaymentLoading(false);
                return;
            }
            const { clientSecret } = res?.data;

            if (res?.data?.off && stripe) {
                try {
                    stripe
                        ?.confirmSetup({
                            elements,
                            clientSecret,
                            confirmParams: {
                                return_url:
                                    "https://89d1697de324e4d41ea4b33ffea165ef.piqsuite.com",
                            },
                            redirect: "if_required",
                        })
                        .then(function (result: any) {
                            if (result.error) {
                                toast.error(result.error.message);
                                setPaymentLoading(false);
                            } else {
                                setPaymentLoading(false);
                                (window as any).fpr(
                                    "referral",
                                    { email: userData?.email },
                                    function () {
                                        (window as any).fpr("click", { cookie_ref_id: "xxx" });
                                    }
                                );
                                toast.success("Payment Successful", {
                                    duration: 2000,
                                });
                            }
                        });

                    setTimeout(() => {
                        dispatch(fetchSubscriptionData());
                    }, 5000);
                } catch (err) {
                    setPaymentLoading(false);
                    console.log(err);
                    throw err;
                } finally {
                    setPaymentLoading(false);
                }
                return;
            }
            const { error } = stripe ? await stripe.confirmPayment({
                elements,
                clientSecret,
                confirmParams: {
                    return_url: "https://89d1697de324e4d41ea4b33ffea165ef.piqsuite.com",
                },
                redirect: "if_required",
            }) : { error: null };

            if (error) {
                setPaymentError(true);
                setPromoCodeError("")
                setPaymentLoading(false);
                throw error;
            } else {
                setPaymentLoading(false);

                (window as any).fpr(
                    "referral",
                    { email: userData?.email },
                    function () {
                        (window as any).fpr("click", { cookie_ref_id: "xxx" });
                    }
                );
                setTimeout(() => {
                    dispatch(fetchSubscriptionData());
                }, 5000);
            }
        } catch (error: any) {
            dispatch(check_if_token_is_expired(error?.response))
            throw error;
        }
    };

    const paymentSubmitHandler = async (e: FormEvent) => {
        e.preventDefault();
        setPaymentError(false);
        try {
            await handlePay();
            afterPaymentStepHandler();
        } catch (err) {
            setPaymentLoading(false);
            console.log("error = ", err)
        }
    }

    return {
        promoCodeSubmitHandler,
        promoCodeRef,
        promoCodeSubmitting,
        promoCodeError,
        promoCode,
        setPromoCode,
        handlePay,
        tc,
        setTc,
        paymentLoading,
        setPaymentLoading,
        paymentSubmitHandler,
        discountAmount,
        discountPercent,
        isMonthly,
    }
};

export default useCheckout;
