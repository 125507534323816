import React from "react";
import { Modal } from "antd";
//@ts-ignore
import privacy from "src/style/Privacy_policy.pdf";

const PrivacyDialog = (props: any) => {

  return (
    <Modal className="terms-iframe-div" open={props.openPrivacyPolicy} onCancel={() => props.setOpenPrivacyPolicy(false)} footer={null} closable={false}>
      <iframe title="privacy" src={privacy} className="terms-iframe" />
    </Modal>
  );
};

export default PrivacyDialog;