import React, { useEffect } from "react";
import useInFeedAds from "src/Hooks/useInFeedAds";
import config from "src/config";
import { trackEvents } from "src/constants/tracker";
import { useTrackerContext } from "src/context/TrackerProvider";
import PepperstoneAd from "../PepperstoneAd";
import useSubscription from "src/Hooks/useSubscription";
import { useLocationContext } from "src/context/LocationProvider";

export const InFeedAds: React.FC<{ item: any, isWidth2: boolean }> = ({ item, isWidth2 }) => {
    const { handleAdsClick } = useInFeedAds()
    const { sendEvent } = useTrackerContext()
    const { has_hiq_subscription } = useSubscription()
    const { isUS } = useLocationContext()
    return (
        <>
            {
                has_hiq_subscription ?
                    (item?.visible_to_high_iq === true ?
                        item?.type === "PEPPERSTONE" ?
                            isWidth2 ?
                                <>
                                    <div
                                        hidden={isUS}
                                        className={`v2-suite-column-card-item`}
                                        style={{ cursor: 'pointer', padding: "0px !important" }} onClick={() => {
                                            handleAdsClick(item);
                                        }}>  <img src={config?.FEED_URL + item?.image_url}
                                            alt="https://piqsuite.com"
                                            style={{ width: "-webkit-fill-available" }}
                                        />
                                    </div>
                                </>
                                : <></>

                            :
                            <div
                                className={`v2-suite-column-card-item`}
                                style={{ cursor: 'pointer', padding: "0px !important" }} onClick={() => {
                                    handleAdsClick(item);
                                }}>
                                <img src={config?.FEED_URL + item?.image_url}
                                    alt="https://piqsuite.com"
                                    style={{ width: "-webkit-fill-available" }}
                                />
                            </div> : (
                            <></>
                        )



                    ) : (
                        item?.type === "PEPPERSTONE" ?
                            isWidth2 ?
                                <>
                                    <div
                                        hidden={isUS}

                                        className={`v2-suite-column-card-item`}
                                        style={{ cursor: 'pointer', padding: "0px !important" }} onClick={() => {
                                            handleAdsClick(item);
                                        }}>  <img src={config?.FEED_URL + item?.image_url}
                                            alt="https://piqsuite.com"
                                            style={{ width: "-webkit-fill-available" }}
                                        />
                                    </div>
                                </>
                                : <></>

                            :
                            <div
                                className={`v2-suite-column-card-item`}
                                style={{ cursor: 'pointer', padding: "0px !important" }} onClick={() => {
                                    handleAdsClick(item);
                                }}>
                                <img src={config?.FEED_URL + item?.image_url}
                                    alt="https://piqsuite.com"
                                    style={{ width: "-webkit-fill-available" }}
                                />
                            </div>
                    )


            }
        </>
    )
}